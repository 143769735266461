import React, {Fragment, useEffect, useState} from "react";
import {
    getRows,
    getMaxPage,
    getCount,
    getCountSearch,
    getColumns,
    getRowsSearch,
    getMaxPageSearch,
    closeDb,
    initDb,
    getVersion,
    updateRow,
    deleteRow
} from "../db";
import {useHistory, useParams} from "react-router-dom";
import Spinner from "./generic/Spinner";
import Pagination from "./Pagination";
import Quantity from "./Quantity";
import ExportFile from "./ExportFile";
import Cell from "./Cell";
import ColumnSettings from "./ColumnSettings";
import SearchHeader from "./SearchHeader";
import RemoveFromExport from "./RemoveFromExport";
import AddRow from "./AddRow";
import TableHead from "./TableHead";
import Synchronize from "./Synchronize/Synchronize";
import {addColumns as addColumnsServer, updateRow as updateRowServer} from "../models/files";
import { v4 as uuid } from 'uuid';

function FileTab({token, files, changeToken, changeUser, setErrors, setSuccesses, autoSync, setAutoSync, changeVersion}) {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [page, setPage] = useState(0);
    const [quantity, setQuantity] = useState(20);
    const [maxPage, setMaxPage] = useState(0);
    const [count, setCount] = useState(0);
    const [searchCount, setSearchCount] = useState(0);
    const [columns, setColumns] = useState([]);
    const [searches, setSearches] = useState([]);
    const [regexpSearches, setRegexpSearches] = useState([]);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [cancelToken, setCancelToken] = useState(null);
    const [cancelTokenColumns, setCancelTokenColumns] = useState(null);
    const [version, setVersion] = useState(1);
    const [rfe, setRfe] = useState(false);
    const [fileName, setFileName] = useState(null);

    const {fileUuid} = useParams();
    const history = useHistory();

    useEffect(() => {
        try {
            const foundFileName = files.find(value => value.uuid === fileUuid).fileName;
            setFileName(foundFileName);
        }
        catch (e) {
            console.error(e);
        }
    }, [fileUuid]);

    useEffect(() => {
        if (fileName) {
            setRows([]);
            setLoading(true);
            setSelectedIndex(null);
            setPage(0);
            setMaxPage(0);
            setColumns([]);
            setErrors([]);
            setSuccesses([]);
            setAutoSync(false);

            try {
                setVersion(getVersion(fileName));
                const searchesFromLocalStorage = localStorage.getItem(fileName + "searches");
                if (searchesFromLocalStorage) {
                    setSearches(JSON.parse(searchesFromLocalStorage));
                }
                else {
                    setSearches([]);
                }
                console.log("Settings searches: " + searchesFromLocalStorage);

                const regexpSearchesFromLocalStorage = localStorage.getItem(fileName + "regexpSearches");
                if (regexpSearchesFromLocalStorage) {
                    setRegexpSearches(JSON.parse(regexpSearchesFromLocalStorage));
                }
                else {
                    setRegexpSearches([]);
                }

                setSortedColumn(null);



                if (cancelTokenColumns) {
                    cancelTokenColumns.cancel();
                }

                let newCancelToken = {};

                renewColumns(newCancelToken).catch(err => {
                    console.error(err);
                });

                setCancelTokenColumns(newCancelToken);

            }
            catch (e) {
                history.push('/addFile');
            }
        }
        return () => {
            if (cancelToken) {
                cancelToken.cancel();
            }
            if (cancelTokenColumns) {
                cancelTokenColumns.cancel();
            }
        }
    }, [fileName]);

    useEffect(() => {
        if (selectedIndex === null && fileName) {
            if (cancelToken) {
                cancelToken.cancel();
            }

            let newCancelToken = {};


            changeRows(newCancelToken).catch(error => {
                console.error(error);
            });
            setCancelToken(newCancelToken);
        }
    }, [quantity, page, searches, regexpSearches, sortedColumn, selectedIndex]);

    async function changeRfe(evt) {
        const rfe = evt.target.checked;
        setRfe(rfe);
        try {
            for (let i = 0; i <= maxPage; i++) {
                let rowsFromDb;

                if (searches.length > 0 || regexpSearches.length > 0) {
                    rowsFromDb = await getRowsSearch(fileName, i, quantity, searches, regexpSearches, sortedColumn);

                    rowsFromDb = rowsFromDb.map(value => {
                        const newRow = {...value};

                        for (let key in newRow.row) {
                            let text = newRow.row[key].replace(/</g, '&lt;').replace(/>/g, '&gt;');

                            try {
                                for (let i = 0; i < searches.length; i++) {
                                    if (searches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW') === key) {
                                        text = text.replace(searches[i].search.replace(/</g, '&lt;').replace(/>/g, '&gt;'), '<mark>$&</mark>');
                                    }
                                }
                                for (let i = 0; i < regexpSearches.length; i++) {
                                    if (regexpSearches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW') === key) {
                                        text = text.replace(new RegExp(regexpSearches[i].search.replace(/</g, '&lt;').replace(/>/g, '&gt;'), 'g'), '<mark>$&</mark>');
                                    }
                                }
                            }
                            catch (e) {
                                console.error(e);
                            }

                            newRow.row[key] = {text, value: newRow.row[key]}
                        }
                        return newRow;
                    });
                }
                else {
                    rowsFromDb = await getRows(fileName, i, quantity, sortedColumn);
                    rowsFromDb = rowsFromDb.map(value => {
                        const newRow = {...value};
                        for (let key in newRow.row) {
                            if (isNaN(newRow.row[key])) {
                                newRow.row[key] = {text: newRow.row[key].replace(/</g, '&lt;').replace(/>/g, '&gt;'), value: newRow.row[key]}
                            }
                            else {
                                newRow.row[key] = {text: newRow.row[key], value: newRow.row[key]}
                            }
                        }
                        return newRow;
                    });
                }

                for (let i = 0; i < rowsFromDb.length; i++) {
                    const oldRow = rowsFromDb[i];

                    const row = {
                        removeFromExport: rfe,
                        row: {},
                        id: oldRow.id
                    };

                    for (let key in oldRow.row) {
                        row.row[key] = oldRow.row[key].value;
                    }

                    await updateRow(row, fileName);

                    if (token && autoSync) {
                        await updateRowServer(fileName, row, columns, token, changeToken, changeUser);
                    }
                }
            }

            setRows(prevState => {
                return [...prevState].map(value => {
                    return {
                        ...value,
                        removeFromExport: rfe
                    };
                });
            });
        }
        catch (e) {
            console.error(e);
        }
    }

    async function changeRows(newCancelToken = {}) {
        try {
            setLoading(true);
            let maxPage = 0;

            let canceled = false;
            newCancelToken.cancel = () => {
                canceled = true;
            };
            if (searches.length > 0 || regexpSearches.length > 0) {
                maxPage = await getMaxPageSearch(fileName, quantity, searches, regexpSearches);
                if (!canceled) {
                    setSearchCount(await getCountSearch(fileName, searches, regexpSearches));
                }
            }
            else {
                maxPage = await getMaxPage(fileName, quantity);
            }

            if (!canceled) {
                setMaxPage(maxPage);
            }

            if (page > maxPage && maxPage !== -1) {
                if (!canceled) {
                    setPage(0);
                }
            }
            else {
                let rowsFromDb = [];

                if (searches.length > 0 || regexpSearches.length > 0) {
                    rowsFromDb = await getRowsSearch(fileName, page, quantity, searches, regexpSearches, sortedColumn);

                    rowsFromDb = rowsFromDb.map(value => {
                        const newRow = {...value};

                        for (let key in newRow.row) {
                            let text = newRow.row[key].replace(/</g, '&lt;').replace(/>/g, '&gt;');

                            try {
                                for (let i = 0; i < searches.length; i++) {
                                    if (searches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW') === key) {
                                        text = text.replace(searches[i].search.replace(/</g, '&lt;').replace(/>/g, '&gt;'), '<mark>$&</mark>');
                                    }
                                }
                                for (let i = 0; i < regexpSearches.length; i++) {
                                    if (regexpSearches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW') === key) {
                                        text = text.replace(new RegExp(regexpSearches[i].search.replace(/</g, '&lt;').replace(/>/g, '&gt;'), 'gu'), '<mark>$&</mark>');
                                    }
                                }
                            }
                            catch (e) {
                                console.error(e);
                            }


                            //TODO check overlap if regexp contains something with <mark></mark>

                            newRow.row[key] = {text, value: newRow.row[key]}
                        }
                        return newRow;
                    });
                }
                else {
                    rowsFromDb = await getRows(fileName, page, quantity, sortedColumn);
                    rowsFromDb = rowsFromDb.map(value => {
                        const newRow = {...value};
                        for (let key in newRow.row) {
                            if (isNaN(newRow.row[key])) {
                                newRow.row[key] = {text: newRow.row[key].replace(/</g, '&lt;').replace(/>/g, '&gt;'), value: newRow.row[key]}
                            }
                            else {
                                newRow.row[key] = {text: newRow.row[key], value: newRow.row[key]}
                            }
                        }
                        return newRow;
                    });
                }

                if (!canceled) {
                    let rfe = true;
                    for (let i = 0; i < rowsFromDb.length; i++) {
                        if (!rowsFromDb[i].removeFromExport) {
                            rfe = false;
                            break;
                        }
                    }

                    if (rowsFromDb.length === 0) {
                        rfe = false;
                    }

                    setRfe(rfe);
                    setRows(rowsFromDb);
                }
            }

            if (!canceled) {
                setCount(await getCount(fileName));
                setMaxPage(maxPage);
                setLoading(false);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function renewColumns(newCancelToken = {}) {
        try {
            let canceled = false;
            newCancelToken.cancel = () => {
                canceled = true;
            };

            const columnsFromLocalStorage = localStorage.getItem(fileName + "columns");

            if (columnsFromLocalStorage) {
                if (!canceled) {
                    setColumns(JSON.parse(columnsFromLocalStorage));
                }
            }
            else {
                const columnNames = await getColumns(fileName);
                const width = 100 / columnNames.length;
                if (!canceled) {
                    setColumns(columnNames.map((column, index) => {
                        return {
                            id: uuid(),
                            name: column,
                            width,
                            show: true,
                            type: "text",
                            realIndex: index,
                            defaultValue: ""
                        }
                    }));
                }
            }

            if (!canceled) {
                setCount(await getCount(fileName));
                setMaxPage(await getMaxPage(fileName, quantity));
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    function changeSearch(column, search) {
        const newSearches = [...searches];

        const foundSearch = newSearches.findIndex(search => search.column === column);

        if (foundSearch !== -1) {
            if (search === "") {
                newSearches.splice(foundSearch, 1);
            }
            else {
                newSearches[foundSearch].search = search;
            }
        }
        else if (search !== ""){
            newSearches.push({
                column: column,
                search: search
            });
        }

        setSearches(newSearches);
        localStorage.setItem(fileName + "searches", JSON.stringify(newSearches));
    }

    function changeRegexpSearch(column, search) {
        const newRegexpSearches = [...regexpSearches];

        const foundSearch = newRegexpSearches.findIndex(search => search.column === column);

        if (foundSearch !== -1) {
            if (search === "") {
                newRegexpSearches.splice(foundSearch, 1);
            }
            else {
                newRegexpSearches[foundSearch].search = search;
            }
        }
        else if (search !== ""){
            newRegexpSearches.push({
                column: column,
                search: search
            });
        }

        setRegexpSearches(newRegexpSearches);
        localStorage.setItem(fileName + "regexpSearches", JSON.stringify(newRegexpSearches));
    }

    function changeColumns(newColumns) {
        console.log("Changing columns to ", newColumns);
        setColumns(newColumns);
        localStorage.setItem(fileName + "columns", JSON.stringify(newColumns));
    }

    async function addColumn(columnToAdd, type, defaultValue) {
        try {
            const newColumns = [...columns.map(column => column.name)];
            newColumns.push(columnToAdd);

            const maxPage = await getMaxPage(fileName, 50);

            let increment = 1;

            for (let i = 0; i <= maxPage; i++) {
                const rows = await getRows(fileName, i, 50);
                for (let i = 0; i < rows.length; i++) {
                    const keys = Object.keys(rows[i].row);
                    if (!keys.includes(columnToAdd.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW'))) {
                        if (defaultValue === "autoincrement") {
                            rows[i].row[columnToAdd.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = increment.toString();
                            increment ++;
                        }
                        else if (defaultValue === "uuid") {
                            rows[i].row[columnToAdd.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = uuid();
                        }
                        else if (defaultValue) {
                            rows[i].row[columnToAdd.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = defaultValue.toString();
                        }
                        else {
                            rows[i].row[columnToAdd.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = '';
                        }
                        await updateRow(rows[i], fileName);
                    }
                }
            }
            closeDb(fileName);
            await initDb(fileName, newColumns, version + 1);
            const columnsToSet = [...columns];
            const columnToPush = {
                name: columnToAdd,
                type,
                show: false,
                width: 0,
                realIndex: columnsToSet.length,
                defaultValue
            }
            columnsToSet.push(columnToPush);
            changeColumns(columnsToSet);
            if (cancelToken) {
                cancelToken.cancel();
            }

            let newCancelToken = {};


            changeRows(newCancelToken).catch(error => {
                console.error(error);
            });
            setCancelToken(newCancelToken);
            changeVersion(fileName, version + 1);
            setVersion(version + 1);
            if (token && autoSync) {
                await addColumnsServer(fileName, [columnToPush], token, changeToken, changeUser);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function removeRow(rowId) {
        try {
            await deleteRow(fileName, rowId);
            if (cancelToken) {
                cancelToken.cancel();
            }

            let newCancelToken = {};


            changeRows(newCancelToken).catch(error => {
                console.error(error);
            });

            setCancelToken(newCancelToken);
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={"mt-2"}>
            {columns.length === 0 ? <Spinner /> :
                <Fragment>
                    <div className={"d-flex justify-content-between align-items-center bg-white p-3 border rounded"}>
                        <div>
                            <div className={"d-flex justify-content-between"}>
                                <ExportFile searches={searches} regexpSearches={regexpSearches} fileName={fileName} columns={columns}/>
                                <AddRow autoSync={autoSync} token={token} changeToken={changeToken} changeUser={changeUser} columns={columns} changeRows={changeRows} setCount={setCount} fileName={fileName}/>
                            </div>

                            <Synchronize changeColumns={changeColumns} loading={loading} setLoading={setLoading} columns={columns} fileName={fileName} autoSync={autoSync} setAutoSync={setAutoSync} changeRows={changeRows} token={token} count={count} changeToken={changeToken} changeUser={changeUser} setErrors={setErrors} setVersion={setVersion} changeVersion={changeVersion}/>
                        </div>
                        <div>
                            <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                            <p className={"text-center m-0"}>Number of rows: {count}</p>
                            {(searches.length > 0 || regexpSearches.length) > 0 && <p className={"text-center m-0"}>Found rows: {searchCount}/{count} ({Math.floor(searchCount / count * 10000) / 100}%)</p>}
                        </div>
                        <div>
                            <ColumnSettings fileName={fileName} changeUser={changeUser} changeToken={changeToken} token={token} autoSync={autoSync} columns={columns} setColumns={changeColumns} addColumn={addColumn}/>
                            <Quantity quantity={quantity} setQuantity={setQuantity} />
                        </div>
                    </div>

                    <div className={"bg-white p-3 my-2 border rounded"}>
                                <table className={"table table-light table-striped"}>
                                    <TableHead columns={columns} sortedColumn={sortedColumn} setSortedColumn={setSortedColumn}/>
                                    <tbody >
                                    <tr>
                                        <td><input type="checkbox" checked={rfe} onChange={changeRfe}/></td>
                                        {columns.filter(column => column.show).map((column, index) => {
                                            return <SearchHeader changeRegexpSearch={changeRegexpSearch} column={column} key={column.realIndex} changeSearch={changeSearch} search={searches.find(({column: columnSearch}) => {
                                                return columnSearch === column.name
                                            }) || {search: ""}} regexpSearch={regexpSearches.find(({column: columnSearch}) => {
                                                return columnSearch === column.name
                                            }) || {search: ""}}/>
                                        })}
                                        <td><button type={"button"} className={"btn btn-warning"} onClick={(evt) => {
                                            setSearches([]);
                                            setRegexpSearches([]);
                                            localStorage.setItem(fileName + "regexpSearches", JSON.stringify([]));
                                            localStorage.setItem(fileName + "searches", JSON.stringify([]));
                                        }}>Clear search</button></td>
                                    </tr>
                                    </tbody>
                                    <tbody>
                                    {loading &&
                                        <tr>
                                            <td colSpan={columns.filter(column => column.show).length + 2}><Spinner/></td>
                                        </tr>}
                                        <Fragment>
                                            {rows.map((row, rowKey) => {
                                                return (
                                                    <tr key={row.id}>
                                                        <td><RemoveFromExport columns={columns} autoSync={autoSync} token={token} changeToken={changeToken} changeUser={changeUser} rowId={rowKey} rows={rows}
                                                                              initialChecked={row.removeFromExport}
                                                                              setRows={setRows} fileName={fileName}/></td>
                                                        {Object.values(row.row).filter((row, index) => {
                                                            return columns[index].show
                                                        }).map((value, columnKey) => {
                                                            return <Cell autoSync={autoSync} fileName={fileName} rows={rows} setRows={setRows} columns={columns}
                                                                         key={rowKey.toString() + columnKey.toString()}
                                                                         setSelectedIndex={setSelectedIndex}
                                                                         selectedIndex={selectedIndex} value={value.value} textToShow={value.text}
                                                                         columnKey={columnKey} rowId={row.id} rowKey={rowKey} token={token} changeToken={changeToken} changeUser={changeUser}/>
                                                        })}
                                                        <td><button className={"btn btn-danger"} onClick={async (evt) => {
                                                            await removeRow(row.id);
                                                        }}>Remove</button></td>
                                                    </tr>);
                                            })}
                                        </Fragment>
                                    </tbody>
                                </table>
                    </div>

                    <div className={"bg-white border rounded p-3 align-middle"}>
                        <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                    </div>
                </Fragment>}
        </div>
    );
}

export default FileTab;
