import React from "react";
import Header from "./Header";

function TableHead({columns, sortedColumn, setSortedColumn}) {

    return (
        <thead className={"thead-dark sticky-top top-0"} >
        <tr>
            <th style={{wordBreak: "normal"}} title={"Remove From Export"}>R.F.E</th>
            {columns.filter(column => column.show).map((column, index) => {
                return <Header sortedColumn={sortedColumn} setSortedColumn={setSortedColumn} column={column} key={index}/>
            })}
            <th>Actions</th>
        </tr>
        </thead>
    );
}

export default TableHead;
